import React, { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';

import { menuNavigationEvent } from 'src/lib/analytics';

import styles from './Secondary.module.scss';

const setClass = (element, callback) => {
  const atStart = element.scrollLeft <= 0;
  const atEnd = element.scrollLeft >= element.scrollWidth - element.offsetWidth;

  if (atStart && !atEnd) {
    callback(styles['secondary--start']);
  } else if (!atStart && !atEnd) {
    callback(styles['secondary--middle']);
  } else if (!atStart && atEnd) {
    callback(styles['secondary--end']);
  } else {
    callback(null);
  }
};

const Secondary = ({ shortName, category, pageLinks }) => {
  const [scrollClass, setScrollClass] = useState('');
  const navRef = useRef();
  const router = useRouter();

  const linkStyles = `nav-link ${styles.secondary__link} ${
    styles[`secondary__link--${category}`]
  }`;

  const isLatestActive = (asPath) =>
    asPath === router.asPath || router.query.topic;

  const isActive = (asPath) => {
    if (asPath === router.asPath) {
      return true;
    }
    if (asPath.indexOf('archives') !== -1 && router.query.year) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setClass(navRef.current, setScrollClass);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <nav
      aria-label="columnist"
      className={classNames('nav', styles.secondary, scrollClass)}
      data-print="none"
      ref={navRef}
      onScroll={(event) => setClass(event.target, setScrollClass)}
    >
      <Link as={`/${category}/${shortName}`} href="/[category]/[shortName]">
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <a
          className={classNames(
            linkStyles,
            isLatestActive(`/${category}/${shortName}`) ? styles.active : ''
          )}
          data-title="latest"
          onClick={() => menuNavigationEvent('subnav', 'latest')}
          onKeyUp={() => menuNavigationEvent('subnav', 'latest')}
        >
          latest
        </a>
      </Link>
      {pageLinks.map((destination) => {
        const asPath = `/${category}/${shortName}/${destination}`;

        return (
          <Link
            as={asPath}
            href={`/[category]/[shortName]/${destination}`}
            key={uuidv4()}
          >
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <a
              className={classNames(
                linkStyles,
                isActive(asPath) ? styles.active : '',
                destination === 'polls' &&
                  styles[`secondary__link--${category}--${destination}`]
              )}
              data-title={destination}
              onClick={() => menuNavigationEvent('subnav', destination)}
              onKeyUp={() => menuNavigationEvent('subnav', destination)}
            >
              {destination}
            </a>
          </Link>
        );
      })}
    </nav>
  );
};

Secondary.propTypes = {
  category: PropTypes.string.isRequired,
  pageLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
  shortName: PropTypes.string.isRequired,
};

export default Secondary;
